import React from 'react';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import thumbnail1 from '../assets/scouts.png';
import thumbnail2 from '../assets/scout.png';
import thumbnail3 from '../assets/sp.jpg';
import thumbnail4 from '../assets/blm.jpg';
import thumbnail5 from '../assets/bl.jpg';

const researchPapers = [
  {
    thumbnail: thumbnail1,
    link: 'https://zenodo.org/records/7965474',
    title: 'Nitrate Scouts: Partizipativ forschen',
  },
  {
    thumbnail: thumbnail2,
    link: 'https://www.buergergesellschaft.de/fileadmin/pdf/gastbeitrag_lud_mossbrucker_240328.pdf',
    title: 'Nitrate Scouts: Authentisches partizipatives Lernen',
  },
  {
    thumbnail: thumbnail3,
    link: 'https://link.springer.com/article/10.1007/s41207-017-0015-z#:~:text=The%20bait%2Dlamina%20method%20was,soil%20processes%20(Kratz%201998).',
    title: 'The bait-lamina earthworm test',
  },
  {
    thumbnail: thumbnail4,
    link: 'https://www.sciencedirect.com/science/article/pii/S1470160X20312176',
    title: 'The bait-lamina earthworm test',
  },
  {
    thumbnail: thumbnail5,
    link: 'https://www.sciencedirect.com/science/article/abs/pii/S0038071723001098',
    title: 'The bait-lamina earthworm test',
  },
];

const Research = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="carousel-container mt-12">
      <h2 className="text-white text-2xl font-bold mb-4">Forschungsarbeiten</h2>
      <p className="text-yellow-500 text-lg mb-6">
        Entdecken Sie eine Sammlung wissenschaftlicher Forschungsarbeiten. Sie bieten Einblicke in verschiedene wissenschaftliche Studien und Erkenntnisse.
      </p>
      <Slider {...settings}>
        {researchPapers.map((paper, index) => (
          <div key={index} className="p-4">
            <motion.div
              className="bg-blue-800 rounded-lg shadow-lg overflow-hidden"
              whileHover={{ scale: 1.1 }} // Enlarge the whole card on hover
            >
              <a href={paper.link} target="_blank" rel="noopener noreferrer">
                <img src={paper.thumbnail} alt={paper.title} className="w-full h-48 object-cover" />
              </a>
              <div className="p-4">
                <h3 className="text-white text-lg font-bold">{paper.title}</h3>
              </div>
            </motion.div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Research;
