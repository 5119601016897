import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ScatterChart, Scatter, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';
import jsPDF from 'jspdf';
import { SectionWrapper } from "../hoc";
import { fadeIn, textVariant, slideIn } from "../utils/motion";
import { styles } from "../styles";
import myLogo from '../assets/worm.jpg';
import nitrateLogo from '../assets/nitrate.png';
import anotherLogo from '../assets/logoo.png';
import html2canvas from 'html2canvas';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';


const sanitizeInput = (input) => {
  const sanitizedInput = input.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  return sanitizedInput;
};




const CalculationTable = () => {
  const initialTableData = Array.from({ length: 16 }, () => Array.from({ length: 16 }, () => ''));

  const [tableData, setTableData] = useState(initialTableData);
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [location, setLocation] = useState('');
  const [raisedBedType, setRaisedBedType] = useState('Gedüngt');
  const [remarks, setRemarks] = useState('');
  const [chartData, setChartData] = useState([]);
  const [showChart, setShowChart] = useState(false);
  const chartRef = useRef();
  const inputRefs = useRef([]);

  useEffect(() => {
    generateChartData();
  }, [tableData]);

  const handleChange = (e, rowIndex, colIndex) => {
    const { value } = e.target;
    if (value === '1' || value === '0' || value === '-' || value === '0.5' || value === '') {
      const newData = tableData.map((row, i) =>
        i === rowIndex ? row.map((val, j) => (j === colIndex ? value : val)) : row
      );
      setTableData(newData);
    }
  };

  const calculateColumnTotal = (colIndex) => {
    return tableData.reduce((acc, row) => {
      const cellValue = row[colIndex] === '' ? 0 : row[colIndex] === '1' ? 1 : row[colIndex] === '-' ? 0.5 : 0;
      return acc + cellValue;
    }, 0);
  };

  const calculateRowTotal = (rowIndex) => {
    return tableData[rowIndex].reduce((acc, val) => {
      const cellValue = val === '' ? 0 : val === '1' ? 1 : val === '-' ? 0.5 : 0;
      return acc + cellValue;
    }, 0);
  };

  const calculateGrandTotal = () => {
    return tableData.reduce((acc, row) => {
      return row.reduce((rowAcc, val) => {
        const cellValue = val === '' ? 0 : val === '1' ? 1 : val === '-' ? 0.5 : 0;
        return rowAcc + cellValue;
      }, acc);
    }, 0);
  };

  const generateChartData = () => {
    const rowData = tableData.map((row, index) => ({
      x: index + 1,
      y: calculateRowTotal(index)
    }));
    setChartData(rowData);
  };

  const handleGenerateReport = () => {
    const doc = new jsPDF();
  
    const logo = new Image();
    logo.src = myLogo;
    const nitrate = new Image();
    nitrate.src = nitrateLogo;
    const another = new Image();
    another.src = anotherLogo;
  
    doc.addImage(logo, 'PNG', 10, 10, 30, 30);
    doc.addImage(nitrate, 'PNG', 50, 10, 30, 30);
    doc.addImage(another, 'PNG', 90, 10, 30, 30);
  
    const titleText = 'Bait Lamina Test';
    doc.setFont('helvetica', 'bold');
    doc.setTextColor('blue');
    doc.setFontSize(24);
    doc.text(titleText, 130, 30);
  
    const reportContent = `
      Name: ${sanitizeInput(name)}
      Date: ${sanitizeInput(date)}
      Location: ${sanitizeInput(location)}
      Raised Beds: ${sanitizeInput(raisedBedType)}
    `;
    doc.setFont('helvetica', 'normal');
    doc.setTextColor('black');
    doc.setFontSize(10);
    doc.text(reportContent, 10, 50);
  
    doc.text(`Remarks:`, 10, 80);
    doc.text(sanitizeInput(remarks), 10, 90, { maxWidth: 190 });
  
    if (chartRef.current) {
      const chartElement = chartRef.current.container;
  
      html2canvas(chartElement).then(canvas => {
        const chartDataURL = canvas.toDataURL('image/png');
        doc.addImage(chartDataURL, 'PNG', 10, 110, 180, 100);
  
        const tableSummary = `
          Summe pro Tiefe: ${tableData.map((row, index) => calculateRowTotal(index)).join(', ')}
          Summe pro Streifen: ${tableData[0].map((_, index) => calculateColumnTotal(index)).join(', ')}
          Summe pro Streifen: ${calculateGrandTotal()}
        `;
        doc.text(tableSummary, 10, 220);
  
        doc.save('report.pdf');
      }).catch(error => {
        console.error('Error adding chart image to PDF:', error);
      });
    } else {
      console.error('No chart data available');
    }
  };

  const handleRefreshData = () => {
    setTableData(initialTableData);
    setName('');
    setDate('');
    setLocation('');
    setRaisedBedType('Gedüngt');
    setRemarks('');
  };

  const handleKeyDown = (e, rowIndex, colIndex) => {
    if (e.key === 'ArrowRight') {
      const nextColIndex = colIndex === 15 ? 0 : colIndex + 1;
      inputRefs.current[rowIndex][nextColIndex].focus();
    } else if (e.key === 'ArrowLeft') {
      const prevColIndex = colIndex === 0 ? 15 : colIndex - 1;
      inputRefs.current[rowIndex][prevColIndex].focus();
    } else if (e.key === 'ArrowDown') {
      const nextRowIndex = rowIndex === 15 ? 0 : rowIndex + 1;
      inputRefs.current[nextRowIndex][colIndex].focus();
    } else if (e.key === 'ArrowUp') {
      const prevRowIndex = rowIndex === 0 ? 15 : rowIndex - 1;
      inputRefs.current[prevRowIndex][colIndex].focus();
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const fileData = event.target.result;
      if (file.type === 'text/csv') {
        parseCSV(fileData);
      } else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        parseExcel(fileData);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const parseCSV = (data) => {
    Papa.parse(data, {
      complete: (result) => {
        const parsedData = result.data.map(row => row.slice(0, 16));
        setTableData(parsedData);
      },
      header: false
    });
  };

  const parseExcel = (data) => {
    const workbook = XLSX.read(data, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    const parsedData = jsonData.map(row => row.slice(0, 16));
    setTableData(parsedData);
  };

  const downloadCSV = () => {
    const csvRows = [];
    const headers = [...Array(16).keys()].map(i => `Column ${i + 1}`);
    csvRows.push(headers.join(','));

    tableData.forEach(row => {
      csvRows.push(row.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    FileSaver.saveAs(blob, 'table-data.csv');
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.aoa_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    FileSaver.saveAs(blob, 'table-data.xlsx');
  };

  const logoVariants = {
    initial: { scale: 1 },
    hover: { scale: 1.2 }
  };
  
  return (
    <motion.div variants={slideIn} initial="hidden" animate="visible">
      <div className="bg-blue-1000 rounded-lg shadow p-4">
        <motion.h2 className={`${styles.sectionHeadText} text-white text-3xl font-bold mb-4`} variants={textVariant}>Berechnungstabelle</motion.h2>

        <div className="mt-10 mb-4 flex">
          <div className="flex-1">
            <motion.h2 className="text-white text-xl font-bold" variants={textVariant}>Über die Berechnungen</motion.h2>
            <motion.p className='mt-3 text-yellow text-lg leading-[30px]' variants={textVariant}>
              Bitte eintragen:
              <br />
              <strong>1</strong> = Leergefressen
              <br />
              <strong>-</strong> = Halb leergefressen (=0.5)
              <br />
              <strong>0/Leer</strong> = Nicht gefressen
              <br />
              Die Berechnungstabellen umfassen 16 Streifen, und jeder Streifen hat 16 Löcher. Dies ergibt insgesamt 256 Löcher (16 Streifen x 16 Löcher).
            </motion.p>
            <motion.h2 className="text-white text-xl font-bold mt-4" variants={textVariant}>Bedeutung der Ergebnisse</motion.h2>
            <motion.p className='mt-3 text-yellow text-lg leading-[30px]' variants={textVariant}>
              Die Summe der Ergebnisse Ihrer Berechnungen gibt einen allgemeinen Einblick in die Aktivität und die Population der Insekten. Ein niedrigerer Wert deutet auf eine geringere Aktivität und Population hin, während ein höherer Wert auf eine höhere Aktivität und Population hinweist. Bitte beachten Sie, dass die Interpretation der Ergebnisse von den spezifischen Testkriterien und dem Kontext abhängt.
            </motion.p>
          </div>
          <motion.div className="flex-none ml-4"
            whileHover="hover"
            variants={logoVariants}>
            <img src={myLogo} alt="Logo" className="h-48 w-48 object-contain" />
          </motion.div>
        </div>
        

        <div className="flex flex-wrap mb-4">
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(sanitizeInput(e.target.value))}
            className="bg-blue-800 rounded-lg p-2 mr-2 mb-2 flex-grow"
          />
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(sanitizeInput(e.target.value))}
            className="bg-blue-800 rounded-lg p-2 mr-2 mb-2"
          />
          <input
            type="text"
            placeholder="Ort"
            value={location}
            onChange={(e) => setLocation(sanitizeInput(e.target.value))}
            className="bg-blue-800 rounded-lg p-2 mr-2 mb-2 flex-grow"
          />
          <select
            value={raisedBedType}
            onChange={(e) => setRaisedBedType(sanitizeInput(e.target.value))}
            className="bg-blue-800 rounded-lg p-2 mb-2"
          >
            <option value="Gedüngt">Gedüngt</option>
            <option value="Ungedüngt">Ungedüngt</option>
          </select>
        </div>

        <div className="mb-4">
          <textarea
            placeholder="Bemerkungen:"
            value={remarks}
            onChange={(e) => setRemarks(sanitizeInput(e.target.value))}
            className="bg-blue-800 rounded-lg p-2 mb-2 w-full resize-none min-h-24"
            rows={4}
          />
        </div>

        <div className="flex items-center mb-4">
          <input
            type="file"
            accept=".csv, .xlsx"
            onChange={handleFileUpload}
            className="bg-blue-800 rounded-lg p-2 mr-2 mb-2"
          />
          <motion.button
            onClick={downloadCSV}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
            variants={textVariant}
          >
            Herunterladen CSV
          </motion.button>
          <motion.button
            onClick={downloadExcel}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            variants={textVariant}
          >
            Herunterladen Excel
          </motion.button>
        </div>

        <div className="overflow-x-auto">
          <table id="calculationTable" className="border-collapse border border-gray-400 mt-4">
            <thead>
              <tr>
                <th className="border border-gray-400 p-2 text-yellow">Streifen, Nummer</th>
                {[...Array(16)].map((_, i) => (
                  <th key={i} className="border border-gray-400 p-1 text-white text-yellow">
                    {i + 1}
                  </th>
                ))}
                <th className="border border-gray-400 p-1 text-white text-lightBrown">Summe pro Tiefe</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td className="border border-gray-400 p-1 text-white text-lightBrown">Loch Nr. (Tiefe) {rowIndex + 1}</td>
                  {row.map((val, colIndex) => (
                    <td key={colIndex} className="border border-gray-400 p-1 text-white text-center text-lightBrown">
                      <input
                        type="text"
                        value={val}
                        onChange={(e) => handleChange(e, rowIndex, colIndex)}
                        className="w-10 h-10 text-xs text-white"
                        data-row-index={rowIndex}
                        data-col-index={colIndex}
                        ref={(input) => {
                          if (!inputRefs.current[rowIndex]) inputRefs.current[rowIndex] = [];
                          inputRefs.current[rowIndex][colIndex] = input;
                        }}
                        onKeyDown={(e) => handleKeyDown(e, rowIndex, colIndex)}
                      />
                    </td>
                  ))}
                  <td className="border border-gray-400 p-1 text-white text-lightBrown">{calculateRowTotal(rowIndex)}</td>
                </tr>
              ))}
              <tr>
                <td className="border border-gray-400 p-1 text-white text-lightBrown">Summe pro Streifen</td>
                {[...Array(16)].map((_, colIndex) => (
                  <td key={colIndex} className="border border-gray-400 p-1 text-white text-lightBrown">
                    {calculateColumnTotal(colIndex)}
                  </td>
                ))}
                <td className="border border-gray-400 p-1 text-white text-lightBrown">{calculateGrandTotal()}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mt-4 flex justify-center flex-wrap">
          <motion.button
            onClick={handleGenerateReport}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4 mb-2"
            variants={textVariant}
          >
            Bericht generieren
          </motion.button>

          <motion.button
            onClick={handleRefreshData}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4 mb-2"
            variants={textVariant}
          >
            Aktualisieren von Daten
          </motion.button>

          <motion.button
            onClick={() => setShowChart(!showChart)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-2"
            variants={textVariant}
          >
            {showChart ? 'Diagramm ausblenden' : 'Diagramm anzeigen'}
          </motion.button>
        </div>

        {showChart && (
          <div className="mt-4 text-center">
            <h2 className="text-white text-xl font-bold mt-4">Visualisierung der Fraßaktivität</h2>
            <div className="flex justify-center">
              <ScatterChart width={1000} height={500} data={chartData} ref={chartRef}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="x" label={{ value: 'Streifen', position: 'insideBottomLeft', offset: -10 }} />
                <YAxis label={{ value: 'Regenwurm-Aktivität', angle: -90, position: 'insideLeft', offset: 10 }} />
                <Tooltip />
                <Legend />
                <Scatter name="Summe" dataKey="y" fill="#8884d8" />
              </ScatterChart>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default SectionWrapper(CalculationTable, "calculationTable");
