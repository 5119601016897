import { Suspense } from 'react';
import React from 'react';
import { motion } from 'framer-motion';
import MyCarousel from "./Carousel";
import { styles } from "../styles";
import { SectionWrapper } from "../hoc";
import { fadeIn, textVariant, slideIn } from "../utils/motion";
import VideoCarousel from "./VideoCarousel"; // Import the VideoCarousel component

const About = () => {
  // Define carousel slides and video thumbnails
  const carouselSlides = [
    <img key="slide1" src="/assets/b.jpg" alt="Image 1" />,
    <img key="slide2" src="/assets/pic.png" alt="Image 2" />,
    <img key="slide3" src="/assets/nit.jpg" alt="Image 3" />,
    <img key="slide4" src="/assets/chi.jpg" alt="Image 4" />,
    <img key="slide5" src="/assets/w.jpg" alt="Image 5" />,
    <img key="slide6" src="/assets/monochrome.jpg" alt="Image 6" />,
  ];

  const videoThumbnails = [
    {
      title: "Bait lamina",
      description: " Was ist der Bait Lamina Test?",
      videoUrl: "https://www.youtube.com/embed/sgppTSuDCyg",
      
    },
    {
      title: "Bodenprobenahme",
      description: "Warum Bodenproben so wichtig sind?",
      videoUrl: "https://www.youtube.com/embed/X007XDsN0Ek",
    },
    {
      title: "Rückgang der Insektenpopulation",
      description: "Neue Forschungsergebnisse in 5 Minuten erklärt",
      videoUrl: "https://www.youtube.com/embed/SclGcaNdKLE",
    },
    {
      title: "Insektenpopulation",
      description: "ENTO- 211 Vorlesung 22: Bewertung der Insektenpopulation",
      videoUrl: "https://www.youtube.com/embed/5Dn1Ug5By_w",
    },
    {
      title: "Crop Scouting",
      description: "Crop Scouting-Bodeninsekten | Bob Wright | 3. Juni 2002",
      videoUrl: "https://www.youtube.com/embed/_WXK5JTRT_s",
    },
  ];

  return (
    <>
      {/* About section content */}
      <motion.div variants={textVariant()}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
          <div className="sm:col-span-1">
            <motion.div variants={slideIn()}>
              {/* Main title with brown color */}
              <p className="font-normal text-lg text-secondary">Einleitung</p>
              <h2 className={`font-bold text-3xl ${styles.sectionHeadTextBrown}`}>Überblick.</h2>
              {/* Light brown text color */}
              <motion.p
                variants={fadeIn("", "", 0.1, 1)}
                className={`mt-4 ${styles.sectionSubText} text-yellow max-w-3xl leading-[30px]`}
              >
                Willkommen bei unserem Bait Lamina Strip Analyse-Tool! Diese Webanwendung wurde entwickelt, um den Prozess der Analyse von Bait Lamina Strip-Testdaten zu rationalisieren und Benutzern effiziente Tools für die Dateneingabe, Berechnung, Visualisierung und Analyse zur Verfügung zu stellen. Bait Lamina Strip-Tests werden häufig in verschiedenen Bereichen wie Entomologie, Landwirtschaft und Umweltwissenschaften eingesetzt, um Insektenpopulationen und Aktivitätsniveaus zu bewerten.

                Traditionell beinhaltet die Analyse von Bait Lamina Strip-Testdaten manuelle Berechnungen und Dateninterpretationen, die zeitaufwändig und fehleranfällig sein können. Unsere Anwendung zielt darauf ab, diesen Prozess zu automatisieren und es den Benutzern zu ermöglichen, Testdaten einfach einzugeben, Berechnungen sofort durchzuführen.
              </motion.p>
            </motion.div>
          </div>

          <div className="sm:col-span-1">
            <motion.div variants={slideIn()}>
              {/* Main title with brown color */}
              <h3 className={`font-bold text-3xl ${styles.sectionHeadTextBrown}`}>Mehr Informationen</h3>
              <div className="mt-4 overflow-y-auto h-full"> {/* Added overflow-y-auto for scrolling */}
                <Suspense fallback={<div>Loading...</div>}>
                  <VideoCarousel videoThumbnails={videoThumbnails} />
                </Suspense>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>

      {/* Key Features */}
      <motion.div variants={slideIn()}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
          <div className="sm:col-span-1">
            <Suspense fallback={<div>Loading...</div>}>
              {/* Wrap MyCarousel with motion.div for hover effect */}
              <motion.div whileHover={{ scale: 1.1 }}>
                <MyCarousel slides={carouselSlides} autoSlide autoSlideInterval={3000} />
              </motion.div>
            </Suspense>
          </div>

          <div className="sm:col-span-1">
            <motion.div variants={slideIn()}>
              {/* Main title with brown color */}
              <h3 className={`font-bold text-3xl ${styles.sectionHeadTextBrown}`}>Hauptmerkmale</h3>
              <motion.ul variants={fadeIn("", "", 0.1, 1)} className={`mt-4 ${styles.sectionSubText} text-lightBrown max-w-3xl leading-[30px]`}>
                <li className="mb-2">• Dateneingabeformulare: Benutzer können neue Testdaten über intuitive Formulare oder Komponenten eingeben, was den Prozess einfach und benutzerfreundlich macht.</li>
                <li className="mb-2">• Berechnungs-Engine: Die Anwendung verwendet fortschrittliche Algorithmen, um relevante Metriken zu berechnen und notwendige Berechnungen auf der Grundlage der gespeicherten Testdaten durchzuführen.</li>
                <li className="mb-2">• DATENVISUALISIERUNG: Erleben Sie Echtzeit-Einblicke mit unserer interaktiven Streudiagramm-Funktion. Durch Klicken auf "Diagramm anzeigen" können Benutzer auf ein automatisiertes Live-Streudiagramm zugreifen, das die Daten dynamisch visualisiert und eine mühelose Erkundung und Analyse ermöglicht.</li>
                <li className="mb-2">• BERICHTE GENERIEREN: Generieren Sie Standardberichte mit nur wenigen Klicks. Unsere Berichtsfunktion ermöglicht es Benutzern, detaillierte Berichte auf der Grundlage der analysierten Daten zu erstellen, was eine fundierte Entscheidungsfindung und eine effektive Kommunikation der Ergebnisse erleichtert.</li>
              </motion.ul>
              <motion.p variants={fadeIn("", "", 0.1, 1)} className={`mt-4 ${styles.sectionSubText} text-lightBrown max-w-3xl leading-[30px]`}>
                <span>“The Earth does not belong to us: we belong to the Earth." - Marlee Matlin</span>
              </motion.p>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default SectionWrapper(About, "about");
