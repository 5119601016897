import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { SectionWrapper } from '../hoc';
import { textVariant, slideIn } from '../utils/motion';
import baitLaminaImage1 from '../assets/try.jpg';  
import baitLaminaImage2 from '../assets/baits.jpg'; 
import baitLaminaImage3 from '../assets/ba.jpg'; 
import tryImage from '../assets/bait.jpg'; 
import HorizontalCarousel from './Research';

const AboutBaitLamina = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const modalRef = useRef(null);
  const scrollPosition = useRef(0);

  const openModal = (image) => {
    setModalImage(image);
    setIsModalOpen(true);
    scrollPosition.current = window.scrollY; // Save the current scroll position
  };

  const closeModal = () => {
    setModalImage(null);
    setIsModalOpen(false);
  };

  const handleScroll = () => {
    if (isModalOpen && Math.abs(window.scrollY - scrollPosition.current) > 50) {
      closeModal();
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      window.addEventListener('scroll', handleScroll);
    } else {
      window.removeEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isModalOpen]);

  return (
    <motion.div variants={slideIn} initial="hidden" animate="visible" className="bg-blue-1000 rounded-lg shadow p-6">
      <motion.h2 className="text-white text-3xl font-bold mb-6" variants={textVariant}>Über die Bait Lamina Methode</motion.h2>
      
      <div className="flex flex-col md:flex-row items-start mt-4">
        <div className="md:w-1/2 md:pr-6">
          <h3 className="text-xl font-bold text-white">Was ist die Bait Lamina Methode?</h3>
          <p className="text-lg text-yellow mt-2 leading-relaxed">
            Die Bait Lamina-Methode ist eine Technik zur Messung der Bodenaktivität von Bodenorganismen. Es besteht aus Streifen mit Ködern, diese Streifen werden in den Boden eingeführt, um die Fressaktivität zu quantifizieren. Aufgrund ihrer Fähigkeit im Boden sind Regenwürmer wichtig, um durch verschiedene Aktivitäten ihren Lebensraum zu verändern oder sogar neue Organismen für andere zu schaffen: Daher gelten sie als "Ökosystemingenieure".
          </p>
          <h3 className="text-xl font-bold text-white mt-6">Wie wird sie durchgeführt?</h3>
          <ul className="text-lg text-yellow mt-2 leading-relaxed list-disc list-inside">
            <li>Die Methode umfasst das Einführen von Köderstreifen in den Boden.</li>
            <li>Diese Streifen bestehen normalerweise aus einer Reihe von Löchern, die mit einer Lebensmittelmischung gefüllt sind:</li>
            <ul className="list-inside list-disc ml-6">
              <li>70% Cellulosepulver</li>
              <li>25% fein gemahlene Weizenkleie</li>
              <li>5% Aktivkohlepulver</li>
            </ul>
            <li>Die Komponenten werden homogen gemischt und mit deionisiertem Wasser zu einer viskosen Paste gerührt.</li>
            <li>Die Paste wird in die Löcher der Bait-Lamina von beiden Seiten in mehreren Zyklen eingefüllt.</li>
            <li>Nach einer bestimmten Zeit werden die Streifen entfernt.</li>
            <li>Die Fressaktivität wird durch das Maß der leeren Löcher bewertet.</li>
          </ul>

          <div className="mt-8">
            <h3 className="text-xl font-bold text-white">Anwendungsgebiete der Bait Lamina Methode</h3>
            <ul className="text-lg text-yellow mt-2 leading-relaxed list-disc list-inside">
              <li>Bewertung der Bodenqualität</li>
              <li>Überwachung der Umweltverschmutzung</li>
              <li> Evaluierung der Bodensanierungsbemühungen</li>
              <li>Überwachung der Auswirkungen von landwirtschaftlichen Praktiken</li>
              <li>Untersuchung der Biodiversität in verschiedenen Ökosystemen</li>
              <li>Politikgestaltung</li>
             
            </ul>
          </div>
        </div>

        <div className="md:w-1/2 md:pl-6">
          <motion.div
            whileHover={{ scale: 1.2 }}
            className="my-4 rounded-lg shadow-lg w-full overflow-hidden cursor-pointer"
            onClick={() => openModal(baitLaminaImage1)}
          >
            <img src={baitLaminaImage1} alt="Bait Lamina Method" className="w-full" />
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.2 }}
            className="my-4 rounded-lg shadow-lg w-full overflow-hidden cursor-pointer"
            onClick={() => openModal(baitLaminaImage2)}
          >
            <img src={baitLaminaImage2} alt="Conducting Bait Lamina Method" className="w-full" />
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.2 }}
            className="my-4 rounded-lg shadow-lg w-full overflow-hidden cursor-pointer"
            onClick={() => openModal(baitLaminaImage3)}
          >
            <img src={baitLaminaImage3} alt="Conducting Bait Lamina Method" className="w-full" />
          </motion.div>

          {/* New Image */}
          <motion.div
            whileHover={{ scale: 1.2 }}
            className="my-4 rounded-lg shadow-lg w-full overflow-hidden cursor-pointer"
            onClick={() => openModal(tryImage)}
          >
            <img src={tryImage} alt="Additional Bait Lamina Method" className="w-full" />
          </motion.div>
        </div>
      </div>

      <div className="mt-8">
        <h3 className="text-xl font-bold text-white">Vorteile der Bait Lamina Methode</h3>
        <ul className="text-lg text-yellow mt-2 leading-relaxed list-disc list-inside">
          <li>Ermöglicht die Bewertung der Bodenfauna-Aktivität auf nicht-invasive Weise.</li>
          <li>Bietet quantitative Daten zur Fressaktivität von Bodenorganismen.</li>
          <li>Kann in verschiedenen Bodenarten und Klimazonen eingesetzt werden.</li>
          <li>Hilft bei der Bewertung der Bodenqualität und der biologischen Vielfalt.</li>
        </ul>
      </div>

      {isModalOpen && (
        <div
          ref={modalRef}
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="relative" onClick={(e) => e.stopPropagation()}>
            <img src={modalImage} alt="Fullscreen" className="w-auto max-h-screen max-w-screen-lg" />
            <button
              onClick={closeModal}
              className="absolute top-0 right-0 mt-4 mr-4 text-black text-3xl font-bold"
            >
              &times;
            </button>
          </div>
        </div>
      )}

      <div className="mt-12">
        <HorizontalCarousel />
      </div>
    </motion.div>
  );
};

export default SectionWrapper(AboutBaitLamina, 'aboutBaitLamina');
