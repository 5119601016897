import React from "react";
import { Tilt } from "react-tilt"; 
import { motion } from "framer-motion";

import { styles } from "../styles";

import { SectionWrapper } from "../hoc";
import { projects } from "../constants";
import { fadeIn, textVariant } from "../utils/motion";


const ProjectCard = ({
  index,
  name,
  description,
  tags,
  image,
  
}) => {
  return (
    <motion.div variants={fadeIn("up", "spring", index * 0.5, 0.75)}>
      <Tilt
        options={{
          max: 45,
          scale: 1,
          speed: 450,
        }}
        className='bg-tertiary p-5 rounded-2xl sm:w-[360px] w-full'
      >
        <div className='relative w-full h-[230px]'>
          <img
            src={image}
            alt='project_image'
            className='w-full h-full object-cover rounded-2xl'
          />

          <div className='absolute inset-0 flex justify-end m-3 card-img_hover'>
            <div
              onClick={() => window.open(source_code_link, "_blank")}
                >
           
            </div>
          </div>
        </div>

        <div className='mt-5'>
          <h3 className='text-white font-bold text-[24px]'>{name}</h3>
          <p className='mt-2 text-white text-[14px]'>{description}</p>
        </div>

     
      </Tilt>
    </motion.div>
  );
};

const Ourmission = () => {
  return (
    <>
      <motion.div variants={textVariant()}>
        <p className={`${styles.sectionSubText} `}>App </p>
        <h2 className={`${styles.sectionHeadText}`}>Ziele</h2>
      </motion.div>

      <div className='w-full flex'>
        <motion.p
          variants={fadeIn("", "", 0.1, 1)}
          className='mt-3 text-yellow text-[17px] max-w-3xl leading-[30px]'
        >
       Durch die Nutzung der Leistungsfähigkeit moderner Webtechnologien und Datenanalysen ermöglicht unser Bait Lamina Strip Analysis Tool Forschern, Agronomen, Schädlingsbekämpfern und Umweltwissenschaftlern, fundierte Entscheidungen zu treffen und wertvolle Einblicke in Insektenpopulationen und -verhalten zu gewinnen  </motion.p>
      </div>

      <div className='mt-20 flex flex-wrap gap-7'>
        {projects.map((project, index) => (
          <ProjectCard key={`project-${index}`} index={index} {...project} />
        ))}
      </div>


    </>
  );
};

export default SectionWrapper(Ourmission, "ourmission");
