import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  About,
  MyCarousel,
  VideoCarousel,
  Hero,
  Navbar,
  AboutBaitLamina,
  Ourmission,
  CalculationTable,
  
  
 
  Footer,
  GoogleTranslate,
  
} from './components';

const App = () => {
  return (
    <Router>
      <div className='relative z-0 bg-primary'>
        <div className='bg-hero-pattern bg-cover bg-no-repeat bg-center'>
          <Navbar />
          <Hero />
        </div>
        <About />
        <MyCarousel />
        <VideoCarousel/>
        <AboutBaitLamina/>
        <Ourmission />
       <CalculationTable/>
        
        <div className='relative z-0'>
          
          <Footer />
          
        </div>
      </div>
      <GoogleTranslate /> 
      
    </Router>
  );
};

export default App;
