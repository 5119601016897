import {
  
  carrent,
  jobit,
  tripguide,
 
} from "../assets";

export const navLinks = [
  {
    id: "about",
    title: "Über",
  },
  {
    id: "aboutBaitLamina",
    title: "Bait Lamina?",
  },
  {
    id: "ourmission",
    title: "App Ziele",
  },
  {
    id: "calculationTable",
    title: "Berechnung",
  },
  
];





const projects = [
  {
    name: "Effizienz",
    description:
   "Optimieren Sie den Prozess der Analyse von Bait Lamina Strip-Testdaten, um Zeit zu sparen und den manuellen Aufwand zu reduzieren.",
 
    image: carrent,
   
  },
  {
    name: "Genauigkeit",
    description:
      "Verbessern Sie die Genauigkeit von Berechnungen und Dateninterpretationen, um zuverlässige Ergebnisse zu gewährleisten.",

    image: jobit,
    
  },
  {
    name: "Einblick",
    description:
      "Bieten Sie Benutzern umfassende Einblicke in Insektenpopulationen und Aktivitätsniveaus für eine fundierte Entscheidungsfindung.",
  
    image: tripguide,
    
  },
];

export { projects };
