import React from 'react';
import { Link } from 'react-router-dom';
import myLogo from '../assets/logoo.png';
import nitrateLogo from '../assets/nitrate.png'; 

const Footer = () => {

  // Link data to the navLinks array
  const updatedNavLinks = [
    { id: 'iso-18311', title: 'ISO 18311:2016', url: 'https://www.iso.org/obp/ui/en/#iso:std:iso:18311:ed-1:v1:en' },
    { id: 'nitrate-scout-hsrw', title: 'Nitrate Scout HSRW', url: 'https://www.hochschule-rhein-waal.de/en/news/nitrate-scouts-project-starts-kick-workshop' },
    { id: 'deutsche-bundesstiftung-umwelt', title: 'Deutsche Bundesstiftung Umwelt', url: 'https://www.dbu.de/' }
  ];

  return (
    <footer className="bg-blue-1000 text-white py-8">
      <div className="container mx-auto flex flex-col lg:flex-row justify-center items-center">
        {/* Left side: Links to sections */}
        <div className="text-center lg:text-left mb-4 lg:mb-0 lg:mr-auto">
          <h4 className="text-xl font-semibold mb-2">Bait Lamina Lab</h4>
          <ul className="list-none">
            {updatedNavLinks.map((nav) => (
              <li className="mb-2" key={nav.id}>
                {/* Updated Link component to use hashes */}
                {nav.url ? (
                  <a href={nav.url} target="_blank" rel="noopener noreferrer" className="text-blue-200 hover:text-white">
                    {nav.title}
                  </a>
                ) : (
                  <Link to={`/#${nav.id}`} className="text-blue-200 hover:text-white">
                    {nav.title}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* Centered Company Logos */}
        <div className="flex justify-center items-center mb-4 lg:mb-0">
          {/* Updated logos with hover effect and links */}
          <a href="https://www.dbu.de/'" target="_blank" rel="noopener noreferrer" className="inline-block w-32 h-32 mr-6 transition duration-300 ease-in-out transform hover:scale-110">
            <img src={myLogo} alt="Company Logo" className="w-full h-full" />
          </a>
          <a href="https://www.hochschule-rhein-waal.de/en/news/nitrate-scouts-project-starts-kick-workshop" target="_blank" rel="noopener noreferrer" className="inline-block w-32 h-32 transition duration-300 ease-in-out transform hover:scale-110">
            <img src={nitrateLogo} alt="Nitrate Logo" className="w-full h-full" />
          </a>
        </div>

        {/* Right side: Company details */}
        <div className="text-center lg:text-right lg:ml-auto">
          {/* Company details */}
          <div className="text-base text-blue-300">
            <p className="mb-1">Green Fab Lab</p>
            <p className="mb-1">Friedrich-Heinrich-Allee 25, 47475 Kamp-Lintfort</p>
            <p className="mb-1">Germany</p>
            <p className="mb-1">
              <a
                href="https://maps.app.goo.gl/i4dQ7Wm9GpQpo5c37"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-300 hover:text-white"
              >
                Auf der Karte ansehen
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
